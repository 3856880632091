exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certificati-professionali-certificati-cqc-merci-js": () => import("./../../../src/pages/certificati-professionali/certificati-cqc-merci.js" /* webpackChunkName: "component---src-pages-certificati-professionali-certificati-cqc-merci-js" */),
  "component---src-pages-certificati-professionali-certificati-cqc-persone-js": () => import("./../../../src/pages/certificati-professionali/certificati-cqc-persone.js" /* webpackChunkName: "component---src-pages-certificati-professionali-certificati-cqc-persone-js" */),
  "component---src-pages-certificati-professionali-certificati-ka-kb-js": () => import("./../../../src/pages/certificati-professionali/certificati-ka-kb.js" /* webpackChunkName: "component---src-pages-certificati-professionali-certificati-ka-kb-js" */),
  "component---src-pages-certificati-professionali-js": () => import("./../../../src/pages/certificati-professionali.js" /* webpackChunkName: "component---src-pages-certificati-professionali-js" */),
  "component---src-pages-certificati-professionali-patentino-muletto-js": () => import("./../../../src/pages/certificati-professionali/patentino-muletto.js" /* webpackChunkName: "component---src-pages-certificati-professionali-patentino-muletto-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-corsi-patente-js": () => import("./../../../src/pages/corsi-patente.js" /* webpackChunkName: "component---src-pages-corsi-patente-js" */),
  "component---src-pages-corsi-patente-patente-am-js": () => import("./../../../src/pages/corsi-patente/patenteAM.js" /* webpackChunkName: "component---src-pages-corsi-patente-patente-am-js" */),
  "component---src-pages-corsi-patente-patente-b-js": () => import("./../../../src/pages/corsi-patente/patenteB.js" /* webpackChunkName: "component---src-pages-corsi-patente-patente-b-js" */),
  "component---src-pages-corsi-patente-patenti-a-js": () => import("./../../../src/pages/corsi-patente/patenti-A.js" /* webpackChunkName: "component---src-pages-corsi-patente-patenti-a-js" */),
  "component---src-pages-corsi-patente-patenti-b-96-js": () => import("./../../../src/pages/corsi-patente/patenti-B96.js" /* webpackChunkName: "component---src-pages-corsi-patente-patenti-b-96-js" */),
  "component---src-pages-corsi-patente-patenti-c-js": () => import("./../../../src/pages/corsi-patente/patenti-C.js" /* webpackChunkName: "component---src-pages-corsi-patente-patenti-c-js" */),
  "component---src-pages-corsi-patente-patenti-d-js": () => import("./../../../src/pages/corsi-patente/patenti-D.js" /* webpackChunkName: "component---src-pages-corsi-patente-patenti-d-js" */),
  "component---src-pages-domande-frequenti-js": () => import("./../../../src/pages/domande-frequenti.js" /* webpackChunkName: "component---src-pages-domande-frequenti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orari-js": () => import("./../../../src/pages/orari.js" /* webpackChunkName: "component---src-pages-orari-js" */),
  "component---src-pages-patente-moto-senza-esame-js": () => import("./../../../src/pages/patente-moto-senza-esame.js" /* webpackChunkName: "component---src-pages-patente-moto-senza-esame-js" */),
  "component---src-pages-patenti-nautiche-js": () => import("./../../../src/pages/patenti-nautiche.js" /* webpackChunkName: "component---src-pages-patenti-nautiche-js" */),
  "component---src-pages-patenti-nautiche-patente-nautica-12-miglia-js": () => import("./../../../src/pages/patenti-nautiche/patente-nautica-12-miglia.js" /* webpackChunkName: "component---src-pages-patenti-nautiche-patente-nautica-12-miglia-js" */),
  "component---src-pages-patenti-nautiche-patente-nautica-no-limit-js": () => import("./../../../src/pages/patenti-nautiche/patente-nautica-no-limit.js" /* webpackChunkName: "component---src-pages-patenti-nautiche-patente-nautica-no-limit-js" */),
  "component---src-pages-pratiche-auto-collaudi-revisioni-js": () => import("./../../../src/pages/pratiche-auto/collaudi-revisioni.js" /* webpackChunkName: "component---src-pages-pratiche-auto-collaudi-revisioni-js" */),
  "component---src-pages-pratiche-auto-duplicato-du-js": () => import("./../../../src/pages/pratiche-auto/duplicato-du.js" /* webpackChunkName: "component---src-pages-pratiche-auto-duplicato-du-js" */),
  "component---src-pages-pratiche-auto-eredita-veicolo-js": () => import("./../../../src/pages/pratiche-auto/eredita-veicolo.js" /* webpackChunkName: "component---src-pages-pratiche-auto-eredita-veicolo-js" */),
  "component---src-pages-pratiche-auto-esportazione-veicolo-js": () => import("./../../../src/pages/pratiche-auto/esportazione-veicolo.js" /* webpackChunkName: "component---src-pages-pratiche-auto-esportazione-veicolo-js" */),
  "component---src-pages-pratiche-auto-intestazione-temporanea-js": () => import("./../../../src/pages/pratiche-auto/intestazione-temporanea.js" /* webpackChunkName: "component---src-pages-pratiche-auto-intestazione-temporanea-js" */),
  "component---src-pages-pratiche-auto-js": () => import("./../../../src/pages/pratiche-auto.js" /* webpackChunkName: "component---src-pages-pratiche-auto-js" */),
  "component---src-pages-pratiche-auto-passaggio-di-proprieta-js": () => import("./../../../src/pages/pratiche-auto/passaggio-di-proprieta.js" /* webpackChunkName: "component---src-pages-pratiche-auto-passaggio-di-proprieta-js" */),
  "component---src-pages-pratiche-auto-reimmatricolazione-veicolo-js": () => import("./../../../src/pages/pratiche-auto/reimmatricolazione-veicolo.js" /* webpackChunkName: "component---src-pages-pratiche-auto-reimmatricolazione-veicolo-js" */),
  "component---src-pages-pratiche-auto-visura-pra-js": () => import("./../../../src/pages/pratiche-auto/visura-pra.js" /* webpackChunkName: "component---src-pages-pratiche-auto-visura-pra-js" */),
  "component---src-pages-pratiche-patenti-conversione-patente-estera-js": () => import("./../../../src/pages/pratiche-patenti/conversione-patente-estera.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-conversione-patente-estera-js" */),
  "component---src-pages-pratiche-patenti-duplicato-deterioramento-js": () => import("./../../../src/pages/pratiche-patenti/duplicato-deterioramento.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-duplicato-deterioramento-js" */),
  "component---src-pages-pratiche-patenti-duplicato-riclassificazione-js": () => import("./../../../src/pages/pratiche-patenti/duplicato-riclassificazione.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-duplicato-riclassificazione-js" */),
  "component---src-pages-pratiche-patenti-duplicato-smarrimento-js": () => import("./../../../src/pages/pratiche-patenti/duplicato-smarrimento.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-duplicato-smarrimento-js" */),
  "component---src-pages-pratiche-patenti-js": () => import("./../../../src/pages/pratiche-patenti.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-js" */),
  "component---src-pages-pratiche-patenti-patente-internazionale-js": () => import("./../../../src/pages/pratiche-patenti/patente-internazionale.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-patente-internazionale-js" */),
  "component---src-pages-pratiche-patenti-rinnovo-patente-js": () => import("./../../../src/pages/pratiche-patenti/rinnovo-patente.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-rinnovo-patente-js" */),
  "component---src-pages-pratiche-patenti-smarrimento-deterioramento-rinnovo-js": () => import("./../../../src/pages/pratiche-patenti/smarrimento-deterioramento-rinnovo.js" /* webpackChunkName: "component---src-pages-pratiche-patenti-smarrimento-deterioramento-rinnovo-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-servizi-aggiuntivi-js": () => import("./../../../src/pages/servizi-aggiuntivi.js" /* webpackChunkName: "component---src-pages-servizi-aggiuntivi-js" */)
}

